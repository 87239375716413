import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { CContainer, CRow, CCol, CImage } from '@coreui/react'
import { apiPublicCourse } from '../../services/api/course'
import lodash from 'lodash'
import { BtnFooter } from '../btnFooter'
import { Link } from 'react-router-dom'

export default class IntroCourse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      courses: [],
      user_info: {},
    }
  }

  loadPublicCourse = async () => {
    let course_data = await apiPublicCourse(1, 1000, [], [])

    this.setState({
      courses: lodash.map(course_data.data.data, function (course) {
        return {
          id: course.id,
          course_name: course.course_name.replaceAll('"', '').replace(')', '').split('-'),
          course_logo: course.course_logo,
        }
      }),
    })
  }

  componentDidMount() {
    this.loadPublicCourse()
  }

  render() {
    const bgStyle1 = {
      background: `#bdddcb`,
      backgroundSize: `25%`,
      overflow: 'hidden',
    }
    return (
      <>
        <div style={bgStyle1}>
          <CContainer className='w-my' style={{ marginBottom: '10%' }} fluid>
            <div className='strike mt-5 mb-5'>
              <span>
                <h1 className='text-pri'>หลักสูตรที่เปิดสอน</h1>
              </span>
            </div>
            <CRow className='mt-5 mb-5'>
              {this.state.courses && this.state.courses.length === 0 && (
                <CCol>
                  <Alert variant='info' className='text-center'>
                    คุณยังไม่ลงทะเบียน กรุณากดที่เมนู คอร์สเรียนทั้งหมด เพื่อลงทะเบียน
                  </Alert>
                </CCol>
              )}
              <>
                {this.state.courses.slice(0, 4).map((course, i) => (
                  <CCol xs={6} md={3} key={course.id}>
                    <Link to={`/course/${course.id}/detail`}>
                      {course.course_logo ? <CImage className='border-image bg-pink' src={`${process.env.REACT_APP_STORAGE_ENDPOINT}/${course.course_logo}`} fluid /> : <CImage className='border-image bg-pink' src={`/top_logo.png`} fluid />}
                      <div id='monitorLeg' className='mt-2 mb-3 bg-pink'></div>
                      <hr className='m-auto mb-3 text-white bg-transparent w-100' style={{ borderTop: '0.6px solid white' }} />
                      <h5 className='text-sec font-h4'>{course.course_name[0]}</h5>
                      <h5 className='fw-normal  font-h4'>{course.course_name[1]}</h5>
                    </Link>
                  </CCol>
                ))}
                <div className='mt-5 d-block d-md-none'></div>
              </>
            </CRow>
          </CContainer>
        </div>
        <CContainer style={{ backgroundColor: '#d2d3d5', height: '20vh' }} fluid>
          <BtnFooter></BtnFooter>
        </CContainer>
      </>
    )
  }
}
