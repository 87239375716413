import React, { Component }  from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Modal } from 'react-bootstrap';
import { apiGetUserList } from '../../../services/api/admin';
import { apiGetListCourseAssignmentFolder } from '../../../services/api/assignment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

class ModalAddCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal : this.props.show,
            modal_data : {
                course_name : ""
            }
        }
    }

        
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let modal_data = this.state.modal_data;

        modal_data[nam] = val;

        this.setState({ modal_data : modal_data });
    }

    handleClose = () => {
        this.props.handleClose()
    }

    modalSubmit = () => {
        this.props.modalSubmit(this.state.modal_data.course_name)
    }

    render() {



        return (
                <Modal show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title >เพิ่มรายวิชาใหม่</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                                <Form.Group className="mb-5" controlId="frmRegisterFirstname">
                                    <Form.Label>ชื่อรายวิชาใหม่</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="course_name" 
                                    onChange={this.myChangeHandler}/>
                                </Form.Group>
                                <Form.Group className="">
                                    <Button variant="secondary" type="button" className="btn-submit" onClick={this.modalSubmit}>
                                        เพิ่มรายวิชา
                                    </Button>
                                </Form.Group>
                            </Form>
                    </Modal.Body>
                </Modal>
        )
    }
}

export default ModalAddCourse;