import React, { Component } from "react";
import FrameHeaderLeftMenu from "../frame/header_left_menu";
import { Alert, Image } from "react-bootstrap";
import { CContainer, CRow, CCol, CImage } from "@coreui/react";
import { apiPublicCourse } from "../../services/api/course";
import lodash from "lodash";
import { BtnFooter } from "../btnFooter";
import { Link } from "react-router-dom";
import "../../index.css";

import officeLeftPink from "../../assets/images/officeLeftPink.png";
class AllAvailableCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      user_info: {},
    };
  }

  loadPublicCourse = async () => {
    let course_data = await apiPublicCourse(1, 1000, [], []);

    this.setState({
      courses: lodash.map(course_data.data.data, function (course) {
        return {
          id: course.id,
          course_name: course.course_name
            .replaceAll('"', "")
            .replace(")", "")
            .split("-"),
          course_logo: course.course_logo,
        };
      }),
    });
  };

  componentDidMount() {
    this.loadPublicCourse();
  }

  render() {
    const bgStyle1 = {
      background: `url(${officeLeftPink}) no-repeat bottom left, #bdddcb`,
      backgroundSize: `25%`,
      overflow: "hidden",
      height: "100%",
      display: "flex",
    };
    return (
      <>
        <FrameHeaderLeftMenu active_menu="course_list"></FrameHeaderLeftMenu>
        <div style={bgStyle1}>
          <CContainer className="w-my" style={{ marginBottom: "10%" }}>
            <div className="strike mt-5">
              <span>
                <h2 className="text-pri">หลักสูตรที่เปิดสอน</h2>
              </span>
            </div>
            <CRow className="mt-4">
              {this.state.my_courses && this.state.my_courses.length === 0 && (
                <CCol>
                  <Alert variant="info" className="text-center">
                    คุณยังไม่ลงทะเบียน กรุณากดที่เมนู คอร์สเรียนทั้งหมด
                    เพื่อลงทะเบียน
                  </Alert>
                </CCol>
              )}
              <>
                {this.state.courses.map((course, i) => (
                  <>
                    <CCol xs={6} md={3} key={course.id}>
                      <div>
                        <Link to={`/course/${course.id}/detail`}>
                          {course.course_logo ? (
                            <CImage
                              className="border-image bg-pink"
                              src={`${process.env.REACT_APP_STORAGE_ENDPOINT}/${course.course_logo}`}
                              fluid
                            />
                          ) : (
                            <CImage
                              className="border-image bg-pink"
                              src={`/top_logo.png`}
                              fluid
                            />
                          )}
                          <div
                            id="monitorLeg"
                            className="mt-2 mb-3 bg-pink"
                          ></div>
                          <hr className="m-auto mt-4 mb-4 text-white w-100" />
                          <h5 className="font-h4" style={{ color: "#28825A" }}>
                            {course.course_name[0]}
                            <p className="fw-normal mb-5">
                              {course.course_name[1]}
                            </p>
                          </h5>
                        </Link>
                      </div>
                    </CCol>
                  </>
                ))}
                <div className="mt-5 d-block d-md-none"></div>
              </>
            </CRow>
          </CContainer>
        </div>
        <CContainer
          style={{ backgroundColor: "#d2d3d5", height: "12em" }}
          fluid
        >
          <BtnFooter></BtnFooter>
        </CContainer>
      </>
    );
  }
}

export default AllAvailableCourse;
