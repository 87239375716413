import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import 'animate.css'
import './style/header.css'
import { apiLogout } from '../../../lib/services/api/auth'
import { Container, Navbar, Nav, Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import UserInfoModal from './modal/user_info'
import { apiGetInfo } from '../../services/api/user'

function HeaderTop(props) {
	const [username, setUsername] = useState('undefied')
	const [position, setPosition] = useState('undefied')
	const [authorized, setAuthorized] = useState(false)

	
		const loadMyInfo = async () => {
			// let user_info = null

			if(!localStorage.getItem("token")){
				return
			}
			if(props.activeMenu === "login"){
				return
			}
			try {
				let user_info = localStorage.getItem('user_info')
				if (!user_info) {
					user_info = await apiGetInfo()
				}
				setUsername(user_info.first_name)
				setPosition(user_info.position)
			} catch (e) {
				console.log('Error:', e)
			}
		}
	
	const logout = async () => {
		console.log('logout called')

		let result = await apiLogout()
		await apiLogout()
		localStorage.clear()
		setInterval(function () {
			window.location.replace('/login')
		}, 1000)
	}

	const showSettings = async () => {
		setTimeout(function () {
			window.location.assign('/setting')
		}, 500)
	}

	const showContactUs = async () => {
		Swal.fire({
			title: '',
			text: `อีเมล admin@trinitech.co.th`,
			imageUrl: 'http://www.trinitech.co.th/th/wp-content/uploads/2010/10/TrinLOGOs1.png',
			imageAlt: '',
			showClass: {
				popup: 'animate__animated animate__zoomIn animate__faster',
			},
			hideClass: {
				popup: 'animate__animated animate__zoomOut animate__faster',
			},
			confirmButtonColor: '#28825A',
		})
	}

	useEffect(() => {
		setAuthorized(localStorage.getItem('token') || localStorage.getItem('skillmeo_token') || localStorage.getItem('mooc_token'))
		loadMyInfo()
	}, [])

	return (
		<>
			<Navbar expand='sm' style={{ fontWeight: '600', fontSize: '20px' }} className={`p-0 ${authorized ? 'header' : ''} ${props.currentPage ? 'header' : ''}`}>
				<Container fluid>
					<Navbar.Brand href='/'>
						<Image src='/shared/blh_logo.png' className='d-inline-block align-top' width={250} alt='logo' />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse className='basic-navbar-nav justify-content-end navbar-text text-center'>
						{authorized ? (
							<>
								<Nav>
									{props.showProfile && (
										<div>
											<UserInfoModal></UserInfoModal>
										</div>
									)}
									<Nav.Link to='/usermanual' as={NavLink} target='blank'>
										<div>
											<Image src='/shared/manual.png' className='image-icon' alt='' />
										</div>
										คู่มือการใช้งาน
									</Nav.Link>
									<Nav.Link onClick={showContactUs}>
										<div>
											<Image src='/shared/contactus.png' className='image-icon' alt='' />
										</div>
										ติดต่อเรา
									</Nav.Link>
									<Nav.Link onClick={showSettings}>
										<div>
											<Image src='/shared/setting.png' className='image-icon' alt='' />
										</div>
										ตั้งค่า
									</Nav.Link>
									<Nav.Link onClick={logout}>
										<div>
											<Image src='/shared/logout.png' className='image-icon' alt='' />
										</div>
										ออกจากระบบ
									</Nav.Link>
								</Nav>
							</>
						) : (
							<>
								<Nav>
									<Nav.Link className='navbar-link-custom' to='/' as={NavLink}>
										หน้าแรก
									</Nav.Link>
									<Nav.Link className='navbar-link-custom' to='/usermanual' as={NavLink} target='blank'>
										คู่มือการใช้งาน
									</Nav.Link>
									<Nav.Link className='navbar-link-custom' onClick={showContactUs}>
										ติดต่อเรา
									</Nav.Link>
								</Nav>
							</>
						)}
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	)
}

export default HeaderTop
