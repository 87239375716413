// src/routes.js
import React from 'react'
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom'
import NotFound from './components/http_error/404'
import Intro from './components/intro'
import MyDashBoard from './components/dashboard/my'
import AuthLoginFrm from './components/auth/login_frm'
import AuthRegisterFrm from './components/auth/register_frm'
import SettingUser from './components/auth/setting'
import AllAvailableCourse from './components/course/all_available'
import CourseDetail from './components/course/detail'
import CourseAssignment from './components/course/assignment'
import CourseAssignmentQuiz from './components/course/assignment_quiz'
import AdminUserList from './components/admin/user/list'
import AdminCourseList from './components/admin/course/list'
import AdminCourseDetail from './components/admin/course/detail'
import AuthForgorPasswordFrm from './components/auth/forgot_password_frm'
import AuthRedeemForgorPasswordTokenFrm from './components/auth/redeem_forgot_password_token_frm'
import UserManual from './lib/components/archives/user_manual'

import lodash from 'lodash'
import LoginWithAuthCode from './components/auth/authlogin'

const dayjs = require('dayjs')

const router = () => {
	console.log('Router called..', localStorage.getItem('token'))

	//validate is token expired
	let expired_at = localStorage.getItem('mooc_expired')
	if (expired_at && dayjs().isAfter(dayjs(expired_at))) {
		localStorage.clear()
	}

	let mooc_user_info = null
	let is_admin = false
	if (localStorage.getItem('mooc_user_info')) {
		try {
			mooc_user_info = JSON.parse(localStorage.getItem('mooc_user_info'))
			console.log('mooc_user_info', mooc_user_info)

			let chk_role = lodash.filter(mooc_user_info.roles, { id: 1 })
			if (chk_role.length > 0) is_admin = true
		} catch (e) {}
	}

	return (
		<BrowserRouter>
			<Routes>
				{localStorage.getItem('mooc_token') ? (
					<>
						<Route path='/' element={<Intro />} />
						<Route path='/sso' element={<LoginWithAuthCode />} />
						<Route path='/dashboard' element={<MyDashBoard />} />
						<Route path='/course/all' element={<AllAvailableCourse />} />
						<Route path='/course/:course_id/detail' element={<CourseDetail />} />
						<Route path='/course/:course_id/learn' element={<CourseAssignment />} />
						<Route path='/course/:course_id/quiz/:course_assignment_id' element={<CourseAssignmentQuiz />} />
						<Route path='/setting' element={<SettingUser />} />
						<Route path='/usermanual' element={<UserManual />} />
						{is_admin && (
							<>
								<Route path='/admin/course/:course_id' element={<AdminCourseDetail />} />
								<Route path='/admin/course' element={<AdminCourseList />} />
								<Route path='/admin/user' element={<AdminUserList />} />
								<Route path='*' element={<NotFound />} />
							</>
						)}
						<Route path='*' element={<NotFound />} />
					</>
				) : (
					<>
						<Route path='/' element={<AuthLoginFrm />} />
						<Route path='/sso' element={<LoginWithAuthCode />} />
						<Route path='/login' element={<AuthLoginFrm />} />
                        {
                            process.env.REACT_APP_ALLOW_SELF_REGISTER == "true" &&
                            <>
                                <Route path='/register' element={<AuthRegisterFrm />} />
                                <Route path='/forgot_password' element={<AuthForgorPasswordFrm />} />
                                <Route path='/forgot_password/:token' element={<AuthRedeemForgorPasswordTokenFrm />} />
                                <Route path='/usermanual' element={<UserManual />} />
                            </>
                        }
						<Route path='*' element={<NotFound />} />
						
					</>
				)}
			</Routes>
		</BrowserRouter>
	)
}
export default router
