import React, { useState } from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash'
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Container } from 'react-bootstrap'
import { apiGetCourse, apiAddCourse, apiGetCourseDetail, apiUploadFile, apiEditCourse, apiDelCourse } from '../../../services/api/course'
import { useParams, useHistory } from 'react-router-dom'
import { apiGetListCourseAssignmentFolder, apiPostCourseAssignmentFolder, apiPutCourseAssignmentFolder, apiDeleteCourseAssignmentFolder, apiPostCourseAssignment, apiDeleteCourseAssignment } from '../../../services/api/assignment'
import { apiAllCourseExternalSource } from '../../../services/api/course'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ModalAddAssignmentFolder from './modal_add_assignment_folder'
import ModalUpdateAssignmentFolder from './modal_update_assignment_folder'
import { useEffect } from 'react'


const AdminCourseDetail = (props) => {
    const [showModalCreateAssignmentFolder, setShowModalCreateAssignmentFolder] = useState(false);
    const [showModalUpdateAssignmentFolder, setShowModalUpdateAssignmentFolder] = useState(false);
    const [selAssignmentFolder, setSelAssignmentFolder] = useState(null);
    const [assignments, setAssignments] = useState({});
    const { course_id } = useParams()
    const [course, setCourse] = useState({});
    const [assignmentFolders, setAssignmentFolders] = useState([]);
    const [selectedExternalSourceName, setSelectedExternalSourceName] = useState([]);

    const [newAssignments, setNewAssignments] = useState({});
    const [editCourse, setEditCourse] = useState({});
    const [externalSources, setExternalSources] = useState([]);

    const updatedExternalSourceName = (external_source_id) => {

        const external_source = externalSources.find((externalSource) => {
            return externalSource.id === Number.parseInt(external_source_id)
        }
        );

        console.log('external_source', external_source)

        if (external_source) {
            setSelectedExternalSourceName(external_source.external_source_name)
        } else {
            setSelectedExternalSourceName('')
        }

    }
    const myChangeCourseHandler = (event) => {
        let nam = event.target.name
        let val = event.target.value
        let new_edit_course = editCourse

        if (nam === 'is_available') {
            new_edit_course[nam] = event.target.checked
        } else if (nam === 'external_source_params_course_id') {
            new_edit_course['external_source_params'] = {
                course_id: val
            }
        } else {
            new_edit_course[nam] = val
        }
        console.log('new_edit_course', new_edit_course)
        setEditCourse({ ...new_edit_course })

        if (nam === 'external_source_id') {
            updatedExternalSourceName(val);
        }
    }

    const deleteCourse = async () => {
        if (window.confirm('ยืนยันการลบวิชานี้')) {
            let res = await apiDelCourse(course_id)
            await Swal.fire({
                icon: 'success',
                text: 'ลบข้อมูลเสร็จสิ้น',
            })
            setInterval(function () {
                window.location.replace('/admin/course')
            }, 1000)
        }
    }

    const updateCourse = async () => {
        try {
            let payload = {
                course_name: editCourse.course_name || course.course_name,
                course_logo: editCourse.course_logo || course.course_logo,
                course_is_allow_self_registration: editCourse.is_available ? true : false,
                course_is_publish: editCourse.is_available ? true : false,
                course_data: {
                    course_detail_1: editCourse.course_detail_1 || course.course_data.course_detail_1,
                    description: editCourse.description || course.course_data.description,
                    cover_photo: editCourse.cover_photo || course.course_data.cover_photo,
                    photo_instructor: editCourse.photo_instructor || course.course_data.photo_instructor,
                    course_instructor: editCourse.course_instructor || course.course_data.course_instructor,
                },
                external_source_id: editCourse.external_source_id || course.external_source_id,
                external_source_params: editCourse.external_source_params || course.external_source_params,
            }

            let res = await apiEditCourse(course_id, payload)
            Swal.fire({
                icon: 'success',
                text: 'เพิ่มข้อมูลเสร็จสิ้น',
            })
            setInterval(function () {
                window.location.replace('/admin/course/' + res.data.id)
            }, 1000)
        } catch (e) {
            console.log(e)
        }
    }

    const updatePicture = async (image_name) => {
        try {
            let upload_result = await apiUploadFile(document.getElementById(image_name).files[0])
            console.log('upload_result', upload_result)
            let store_at = upload_result.data.result.tmp_file
            let edit_course = editCourse
            edit_course[image_name] = store_at
            setEditCourse(edit_course)
        } catch (e) {
            console.log(e)
            await Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
            })
        }
    }

    const updateNewAssignment = async (assignment_folder_id, evt) => {
        let nam = evt.target.name
        let val = evt.target.value

        let new_assignments = newAssignments

        console.log('new_assignments', new_assignments)
        console.log('assignment_folder_id', assignment_folder_id)

        if (nam === 'url') {
            new_assignments[assignment_folder_id.toString()]['assignment_data'][nam] = val
        } else {
            new_assignments[assignment_folder_id.toString()][nam] = val
        }

        setNewAssignments(new_assignments)


    }

    const addAssignment = async (assignment_folder_id) => {
        let new_assignment = newAssignments[assignment_folder_id.toString()]

        //check required are filled
        if (!new_assignment.assignment_data.url || !new_assignment.assignment_name || !new_assignment.assignment_type || !new_assignment.assignment_data.url || !new_assignment.assignment_order) {
            alert('กรุณากรอกข้อมูลให้ครบถ้วน')
            return
        }
        let res = await apiPostCourseAssignment(course_id, new_assignment)
        await Swal.fire({
            icon: 'success',
            text: 'เพิ่มข้อมูลเสร็จสิ้น',
        })

        setInterval(function () {
            window.location.replace('/admin/course/' + course_id)
        }, 1000)
    }

    const loadCourse = async () => {
        let external_sources = await apiAllCourseExternalSource();
        setExternalSources(external_sources.data);
        const courseData = await apiGetCourseDetail(course_id)
        if (courseData.data.course_data === null) {
            courseData.data.course_data = {
                photo_instructor: '',
                course_detail_1: '',
                description: '',
                cover_photo: '',
                course_instructor: '',
            }
        }
        let edit_course = editCourse

        edit_course.course_name = courseData.data.course_name
        edit_course.course_instructor = courseData.data.course_data.course_instructor
        edit_course.description = courseData.data.course_data.description
        edit_course.course_detail_1 = courseData.data.course_data.course_detail_1
        edit_course.course_logo = courseData.data.course_logo
        edit_course.cover_photo = courseData.data.course_data.cover_photo
        edit_course.photo_instructor = courseData.data.course_data.photo_instructor
        edit_course.course_is_publish = courseData.data.course_is_publish
        edit_course.external_source_id = courseData.data.external_source_id
        edit_course.external_source_params = courseData.data.external_source_params
        let folders = await apiGetListCourseAssignmentFolder(course_id)

        let new_assignments = {}
        let assignments = {}

        for (let i = 0; i < folders.data.length; i++) {
            new_assignments[folders.data[i].id.toString()] = {
                assignment_type: 'video',
                assignment_data: {
                    url: null,
                },
                assignment_prerequisite: [],
                assignment_name: null,
                assignment_score: null,
                assignment_order: null,
                assignment_folder_id: folders.data[i].id,
            }
            if (Array.isArray(courseData.data.course_assignment)) {
                assignments[folders.data[i].id.toString()] = courseData.data.course_assignment
                    .filter((a) => {
                        return a.course_assignment_folder_id === folders.data[i].id
                    })
                    .sort((a, b) => {
                        return a.assignment_order - b.assignment_order
                    })
            } else {
                assignments[folders.data[i].id.toString()] = []
            }
        }

        setCourse(courseData.data);
        setEditCourse(edit_course);
        setAssignmentFolders(folders.data);
        setNewAssignments(new_assignments);
        setAssignments(assignments);

        updatedExternalSourceName(courseData.data.external_source_id);
    }

    useEffect(() => {
        loadCourse()

    }, [props])

    const loadUpdateAssignmentFolderModal = (assignment_folder) => {
        setShowModalUpdateAssignmentFolder(true);
        setSelAssignmentFolder(assignment_folder);
    }

    const createAssignmentFolder = async (data) => {
        try {
            let res = await apiPostCourseAssignmentFolder(course_id, data)
            console.log('res', res)
            Swal.fire({
                icon: 'success',
                text: 'เพิ่มข้อมูลเสร็จสิ้น',
            })
            setInterval(function () {
                window.location.reload(true)
            }, 1000)
        } catch (e) {
            console.log(e)
            await Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
            })
        }
    }

    const updateAssignmentFolder = async (data) => {
        try {
            let res = await apiPutCourseAssignmentFolder(course_id, selAssignmentFolder.id, data)
            console.log('res', res)
            Swal.fire({
                icon: 'success',
                text: 'แก้ไขข้อมูลเสร็จสิ้น',
            })
            setInterval(function () {
                window.location.reload(true)
            }, 1000)
        } catch (e) {
            console.log(e)
            await Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
            })
        }
    }

    const deleteAssignment = async (assignment_id) => {
        try {
            if (window.confirm('ยืนยันการลบข้อมูลเนื้อหา')) {
                let res = await apiDeleteCourseAssignment(course_id, assignment_id)
                console.log('res', res)
                Swal.fire({
                    icon: 'success',
                    text: 'ลบข้อมูลเสร็จสิ้น',
                })
                setInterval(function () {
                    window.location.reload(true)
                }, 1000)
            }
        } catch (e) {
            console.log(e)
            await Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
            })
        }
    }

    const deleteAssignmentFolder = async (assignment_folder_id) => {
        try {
            if (window.confirm('ยืนยันการลบข้อมูลกลุ่มของเนื้อหา')) {
                let res = await apiDeleteCourseAssignmentFolder(course_id, assignment_folder_id)
                console.log('res', res)
                Swal.fire({
                    icon: 'success',
                    text: 'ลบข้อมูลเสร็จสิ้น',
                })
                setInterval(function () {
                    window.location.reload(true)
                }, 1000)
            }
        } catch (e) {
            console.log(e)
            await Swal.fire({
                icon: 'error',
                text: 'เกิดข้อผิดพลาด บันทึกข้อมูลได้',
            })
        }
    }

    return (
        <>
            <FrameHeaderLeftMenu active_menu='admin_course'> </FrameHeaderLeftMenu>
            {course ? (
                <Container>
                    <div className='row'>
                        <div className='col-9'>
                            <h3>{course.course_name}</h3>
                        </div>
                        <div className='col-3 text-end'>
                            <Button className='btn-submit' variant='danger' type='button' onClick={deleteCourse}>
                                ลบหลักสูตร
                            </Button>
                        </div>
                    </div>

                    <Card className='mt-3'>
                        <Card.Body>
                            <Card.Title>ข้อมูลรายวิขา</Card.Title>
                            <Form>
                                <Form.Group className='mb-1'>
                                    <Form.Label>ชื่อวิชา</Form.Label>
                                    <Form.Control className='form-input' type='text' placeholder='' name='course_name' defaultValue={course.course_name} onChange={myChangeCourseHandler} />
                                </Form.Group>

                                <Form.Group className='mb-1'>
                                    <Form.Label>ชื่อผู้สอน</Form.Label>
                                    <Form.Control as='textarea' style={{ height: '150px' }} className='form-input' type='text' placeholder='' name='course_instructor' defaultValue={course.course_data?.course_instructor} onChange={myChangeCourseHandler} />
                                </Form.Group>
                                <Form.Group className='mb-4'>
                                    <Form.Label>คำอธิบายผู้สอน</Form.Label>
                                    <Form.Control className='form-input' type='text' placeholder='' name='course_detail_1' defaultValue={course.course_data?.course_detail_1} onChange={myChangeCourseHandler} />
                                </Form.Group>
                                <Form.Group className='mb-4'>
                                    <Form.Label>คำอธิบายรายวิชา</Form.Label>
                                    <Form.Control className='form-input' type='text' placeholder='' name='description' defaultValue={course.course_data?.description} onChange={myChangeCourseHandler} />
                                </Form.Group>

                                <Form.Group className='mb-4'>
                                    <Form.Label>URL ของรูปผู้สอน</Form.Label>
                                    <Form.Control
                                        className='form-input'
                                        type='file'
                                        placeholder=''
                                        name='photo_instructor'
                                        id='photo_instructor'
                                        onChange={() => {
                                            updatePicture('photo_instructor')
                                        }}
                                    />
                                    {editCourse.photo_instructor && <img src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + editCourse.photo_instructor} style={{ maxHeight: '100px' }} alt={editCourse.photo_instructor} />}
                                </Form.Group>
                                <Form.Group className='mb-4'>
                                    <Form.Label>URL ของรูปภาพในหน้ารายละเอียดคอร์ส</Form.Label>
                                    <Form.Control
                                        className='form-input'
                                        type='file'
                                        placeholder=''
                                        name='cover_photo'
                                        id='cover_photo'
                                        onChange={() => {
                                            updatePicture('cover_photo')
                                        }}
                                    />
                                    {editCourse.cover_photo && <img src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + editCourse.cover_photo} style={{ maxHeight: '100px' }} alt={editCourse.cover_photo} />}
                                </Form.Group>
                                <Form.Group className='mb-4'>
                                    <Form.Label>URL ของรูปภาพในหน้า Dashboard และ คอร์สทั้งหมด</Form.Label>
                                    <Form.Control
                                        className='form-input'
                                        type='file'
                                        placeholder=''
                                        name='course_logo'
                                        id='course_logo'
                                        onChange={() => {
                                            updatePicture('course_logo')
                                        }}
                                    />
                                    {editCourse.course_logo && <img src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + editCourse.course_logo} style={{ maxHeight: '100px' }} alt={editCourse.course_logo} />}
                                </Form.Group>
                                <Form.Group className='mb-4'>

                                    <Form.Label>แหล่งของหลักสูตร</Form.Label>
                                    <Form.Select
                                        className='form-input'
                                        name='external_source_id'
                                        id='external_source_id'
                                        onChange={myChangeCourseHandler}
                                        value={editCourse.external_source_id}
                                    >
                                        <option value=''>ภายในระบบ</option>
                                        {externalSources.map((externalSource) => (
                                            <option value={externalSource.id}>{externalSource.external_source_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                {
                                    selectedExternalSourceName == 'My Courseville' &&
                                    <Form.Group className='mb-4'>

                                        <Form.Label>My Courseville Course Id</Form.Label>
                                        <Form.Control
                                            className='form-input'
                                            type='text'
                                            placeholder=''
                                            name='external_source_params_course_id'
                                            id='external_source_params_course_id'
                                            onChange={myChangeCourseHandler}
                                            defaultValue={course.external_source_params?.course_id}
                                        />
                                    </Form.Group>


                                }


                                <Form.Group className='mb-4'>
                                    <Form.Label></Form.Label>
                                    <Form.Check className='form-input' type='switch' label='เปิดให้ผู้เรียนเข้าเรียน' name='is_available' onChange={myChangeCourseHandler} defaultChecked={course.course_is_publish} value={true} />
                                </Form.Group>
                                <Form.Group className='text-center'>
                                    <Button className='btn-submit' variant='secondary' type='button' onClick={updateCourse}>
                                        บันทึกข้อมูลหลักสูตร
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3'>
                        {
                            (editCourse.external_source_id == null || editCourse.external_source_id == "") &&
                            <Card.Body>
                                <Card.Title>ข้อมูลเนื้อหาหลักสูตร</Card.Title>
                                <Button
                                    className='btn-submit'
                                    variant='success'
                                    type='button'
                                    onClick={() => {
                                        setShowModalCreateAssignmentFolder(true)
                                    }}>
                                    เพิ่มกลุ่มของเนื้อหา
                                </Button>

                                {assignmentFolders.map((folder) => (
                                    <Card className='mt-3' key={folder.id}>
                                        <Card.Body>
                                            <Card.Title>
                                                <div className='row'>
                                                    <div className='col-9'>{folder.assignment_folder_name}</div>
                                                    <div className='col-3 text-end'>
                                                        <Button
                                                            variant='danger'
                                                            size='sm'
                                                            onClick={() => {
                                                                deleteAssignmentFolder(folder.id)
                                                            }}>
                                                            ลบ
                                                        </Button>
                                                        <Button
                                                            variant='warning'
                                                            size='sm'
                                                            onClick={() => {
                                                                loadUpdateAssignmentFolderModal(folder)
                                                            }}>
                                                            แก้ไข
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Card.Title>

                                            <Table striped bordered size='sm'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center' width='75'>
                                                            ลำดับ
                                                        </th>
                                                        <th className='text-center' width='100'>
                                                            ประเภท
                                                        </th>
                                                        <th className='text-center'>ชื่อเนื้อหา</th>
                                                        <th className='text-center'>URL</th>
                                                        <th className='text-center' width='50'>
                                                            ---
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assignments[folder.id].map((assignment) => (
                                                        <tr>
                                                            <td>{assignment.assignment_order}</td>
                                                            <td>{assignment.assignment_type}</td>
                                                            <td>{assignment.assignment_name}</td>
                                                            <td>{assignment.assignment_data.url}</td>
                                                            <td className='text-center'>
                                                                <Button
                                                                    variant='danger'
                                                                    size='sm'
                                                                    onClick={() => {
                                                                        deleteAssignment(assignment.id)
                                                                    }}>
                                                                    ลบ
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td>
                                                            <Form.Control
                                                                type='number'
                                                                size='sm'
                                                                placeholder=''
                                                                name='assignment_order'
                                                                onChange={(evt) => {
                                                                    updateNewAssignment(folder.id, evt)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Select
                                                                size='sm'
                                                                placeholder=''
                                                                name='assignment_type'
                                                                onChange={(evt) => {
                                                                    updateNewAssignment(folder.id, evt)
                                                                }}>
                                                                <option value='video'>Video</option>
                                                                <option value='pdf'>PDF</option>
                                                            </Form.Select>
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                className='form-input'
                                                                type='text'
                                                                size='sm'
                                                                placeholder=''
                                                                name='assignment_name'
                                                                onChange={(evt) => {
                                                                    updateNewAssignment(folder.id, evt)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Control
                                                                className='form-input'
                                                                type='text'
                                                                size='sm'
                                                                placeholder=''
                                                                name='url'
                                                                onChange={(evt) => {
                                                                    updateNewAssignment(folder.id, evt)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button
                                                                className='btn-submit'
                                                                variant='success'
                                                                size='sm'
                                                                onClick={() => {
                                                                    addAssignment(folder.id)
                                                                }}>
                                                                เพิ่ม
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </Card.Body>
                        }

                    </Card>

                    <ModalAddAssignmentFolder
                        show={showModalCreateAssignmentFolder}
                        handleClose={() => {
                            setShowModalCreateAssignmentFolder(false)
                        }}
                        modalSubmit={(data) => {
                            createAssignmentFolder(data)
                        }}
                    />

                    <ModalUpdateAssignmentFolder
                        show={showModalUpdateAssignmentFolder}
                        assignment_folder={selAssignmentFolder}
                        handleClose={() => {
                            setShowModalUpdateAssignmentFolder(false)
                        }}
                        modalSubmit={(data) => {
                            updateAssignmentFolder(data)
                        }}
                    />
                </Container>
            ) : (
                <></>
            )}
        </>
    )
}


export default AdminCourseDetail
