import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import { CContainer, CRow, CCol, CForm, CButton, CImage, CCard, CCardTitle, CCardImage, CCardImageOverlay } from '@coreui/react'
import Swal from 'sweetalert2'
import { apiLogin } from '../../services/api/auth'
import { apiGetInfo } from '../../services/api/user'
import './style/login.css'
import './../../index.css'
import LockIcon from '../../assets/images/LockLogin.png'
import ForgetPassIcon from '../../assets/images/ForgetPass.png'
import JoinUsIcon from '../../assets/images/JoinUs.png'
import circleTreeIcon from '../../assets/images/CircleTree.png'
import Footer from '../footer/footer'

class AuthLoginFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      // rememberMe: true,
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeLogin = async (e) => {
    e.preventDefault()
    // console.log(this.state)

    if (!this.state.username) {
      Swal.fire({
        icon: 'error',
        text: 'กรุณาระบุชื่อผู้ใช้งาน',
      })
      return
    }

    let payload = {
      method: 'basic',
      username: this.state.username,
      password: this.state.password,
    }
    let result = await apiLogin(payload)
    if (result.status >= 200 && result.status <= 250 && result.data.token) {
      localStorage.setItem('mooc_token', result.data.token)
      localStorage.setItem('mooc_expire', result.data.expire)
      // if (this.state.rememberMe) {
      //   localStorage.setItem('username', this.state.username)
      //   localStorage.setItem('password', this.state.password)
      // }

      let user_info = await apiGetInfo()
      localStorage.setItem('mooc_user_info', JSON.stringify(user_info.data))
      await Swal.fire({
        icon: 'success',
        text: 'เข้าสู่ระบบสำเร็จ',
      })

      setInterval(function () {
        window.location.replace('/dashboard')
      }, 1000)
    } else {
      if (result.is_found === null) {
        await Swal.fire({
          icon: 'error',
          text: 'ผู้ใช้งานนีั้มีความพยายามเข้าระบบหลายครั้ง กรุณาทดลองเข้าระบบใหม่หลังจาก 5 นาที',
        })
      } else {
        await Swal.fire({
          icon: 'error',
          text: 'ชื่อ หรือ รหัสผ่านไม่ถูกต้อง',
        })
      }
    }
  }
  
  componentDidMount() {

    if (process.env.REACT_APP_ALLOW_ONLY_SSO == "true") {
        window.location.replace(process.env.REACT_APP_MAIN_URL);
    }
  }
  render() {
    return ( process.env.REACT_APP_ALLOW_ONLY_SSO != "true" &&
      <>
        <div className='bg-style'>
          <CContainer fluid>
            <CRow>
              <CCol xs={6} md={4}>
                <CImage width={600} style={{ position: 'absolute', marginLeft: '-12px' }} src={LockIcon} fluid />
              </CCol>
              <CCol xs={6} md={4} className='text-white mt-sm-5' style={{ position: 'relative', marginTop: '10%' }}>
                <div className='font-h3'>ยินดีต้อนรับสู่</div>
                <h1 className='font-h1 text-pri' >
                  LIFELONG LEARNING PLATFORM
                </h1>
                <p className='font-p'>
                  เพื่อการเรียนรู้ตามความต้องการ (LEARNING ON-DEMAND)
                </p>
              </CCol>
              <CCol xs={12} md={4} className='align-self-start'>
                <CContainer fluid>
                  <center>
                    <CCard className='bg-transparent border-0 mw-login'>
                      <CCardImage src={circleTreeIcon} />
                      <CCardImageOverlay style={{ maxWidth: '660px' }}>
                        <CForm className='w-75' style={{ marginTop: '25%' }} onSubmit={this.activeLogin}>
                          <CCardTitle className='login text-pri'>MEMBER LOGIN</CCardTitle>
                          <Form.Group controlId='formUsername'>
                            <Form.Control className='login text-pri bg-tri' style={{ width: '65%' }} type='text' onChange={this.myChangeHandler} name='username' placeholder='Username' />
                          </Form.Group>
                          <Form.Group controlId='formPassword'>
                            <Form.Control className='login text-pri bg-tri' style={{ width: '65%' }} type='password' onChange={this.myChangeHandler} name='password' placeholder='Password' />
                          </Form.Group>
                          <CButton className='w-100 text-tri bg-sec border-sec login-button' shape='rounded-1' type='submit'>
                            LOGIN
                          </CButton>
                        </CForm>
                      </CCardImageOverlay>
                    </CCard>
                  </center>
                </CContainer>
                <CContainer className='position-relative mw-login btn-fg-regis mt-5' fluid>
                  <CRow>
                    <CCol className='text-center d-flex justify-content-center' xs={6}>
                      <Link className='text-pri d-flex d-md-block align-items-center font-h5' to='/forgot_password'>
                        <CImage className='mb-3' align='center' width={30} src={ForgetPassIcon} fluid />
                        Forgot Password
                      </Link>
                    </CCol>
                    <CCol className='text-center d-flex justify-content-center' xs={6}>
                      <Link className='text-pri d-flex d-md-block align-items-center font-h5' to='/register'>
                        <CImage className='mb-3' align='center' width={45} src={JoinUsIcon} fluid />
                        &nbsp;Join Us
                      </Link>
                    </CCol>
                  </CRow>
                </CContainer>
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <CContainer style={{ marginTop: '10%' }} fluid>
          <Footer></Footer>
        </CContainer>
      </>
    )
  }
}

export default AuthLoginFrm
