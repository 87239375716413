import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HeaderTop from '../../lib/components/frame/header'
import { CImage, CNavbar, CContainer, CNavLink, CNavbarText } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable, faUsers } from '@fortawesome/free-solid-svg-icons'
import dashboardIcon from '../../assets/images/dashboardIcon.png'
import dashboardIcon_Active from '../../assets/images/dashboardIcon_Active.png'
import courseIcon from '../../assets/images/courseIcon.png'
import courseIcon_Active from '../../assets/images/courseIcon_Active.png'
import ProfileSettingIcon from '../../assets/images/ProfileSettingIcon.png'
import ProfileSettingIcon_Active from '../../assets/images/ProfileSettingIcon_Active.png'
import lodash from 'lodash'
import '../../index.css'

class FrameHeaderLeftMenu extends Component {
	constructor(props) {
		super(props)
		let mooc_user_info = null
		let is_admin = false

		try {
			if(!localStorage.getItem('mooc_user_info')){
				return
			}
			mooc_user_info = JSON.parse(localStorage.getItem('mooc_user_info'))
			// console.log('user_info', user_info)
			let chk_role = lodash.filter(mooc_user_info.roles, { id: 1 })
			if (chk_role.length > 0) is_admin = true
			this.state = {
				user_info: mooc_user_info,
				is_admin: is_admin,
				pageNow: '',
				pageDes: '',
			}
		} catch (e) {
			console.error(e)
		}
		if (this.props.active_menu === 'dashboard') {
			this.state.pageNow = 'DASHBOARD'
			this.state.pageDes = 'หลักสูตรที่กำลังศึกษาอยู่'
		}
		if (this.props.active_menu === 'course_list') {
			this.state.pageNow = 'คอร์สเรียนทั้งหมด'
			this.state.pageDes = 'หลักสูตรที่เปิดสอน'
		}
		if (this.props.active_menu === 'setting') {
			this.state.pageNow = 'ตั้งค่า'
			this.state.pageDes = 'ข้อมูลส่วนตัว'
		}
		if (this.props.active_menu === 'admin_course') {
			this.state.pageNow = 'ตั้งค่า'
			this.state.pageDes = 'จัดการคอร์สเรียน'
		}
		if (this.props.active_menu === 'admin_user') {
			this.state.pageNow = 'ตั้งค่า'
			this.state.pageDes = 'จัดการผู้ใช้งาน'
		}
	}

	render() {
		return (
			<>
				{/* <HeaderTop /> */}
				<HeaderTop showProfile='true'>
					<div />
				</HeaderTop>
				<CNavbar colorScheme='light'>
					<CContainer md>
						<CNavbarText component={Link} to='#'>
							<h4 className='font-locate-pri-nav fw-normal mt-xl-1 text-pri'>
								{this.state.pageNow} &nbsp;
								<span className='d-block d-xl-inline text-sec font-locate-sec-nav'>{this.state.pageDes}</span>
							</h4>
						</CNavbarText>
						<div className='d-inline-flex'>
							<CNavLink component={Link} to='/dashboard' className='mx-1 mx-md-2'>
								<CImage className='img-menu-nav' src={this.props.active_menu === 'dashboard' ? dashboardIcon_Active : dashboardIcon} fluid />
								<p className='d-none d-xl-inline'>DASHBOARD</p>
							</CNavLink>
							<CNavLink component={Link} to='/course/all' className='mx-1 mx-md-2'>
								<CImage className='img-menu-nav' src={this.props.active_menu === 'course_list' ? courseIcon_Active : courseIcon} fluid />
								<p className='d-none d-xl-inline'>คอร์สเรียนทั้งหมด</p>
							</CNavLink>
							<CNavLink component={Link} to='/setting' className='mx-1 mx-md-2'>
								<CImage className='img-menu-nav' src={this.props.active_menu === 'setting' ? ProfileSettingIcon_Active : ProfileSettingIcon} fluid />
								<p className='d-none d-xl-inline'>ตั้งค่าข้อมูลส่วนตัว</p>
							</CNavLink>
							{this.state.is_admin && (
								<>
									<CNavLink component={Link} to='/admin/course' className='d-flex flex-row mx-1 mx-md-2'>
										<FontAwesomeIcon icon={faTable}/>
										<p className='d-none d-xl-inline mx-1 mx-md-2 mt-3'>จัดการคอร์สเรียน</p>
									</CNavLink>
									<CNavLink component={Link} to='/admin/user' className='d-flex flex-row mx-1 mx-md-2'>
										<FontAwesomeIcon icon={faUsers}/>
										<p className='d-none d-xl-inline mx-1 mx-md-2 mt-3'>จัดการผู้ใช้งาน</p>
									</CNavLink>
								</>
							)}
						</div>
					</CContainer>
				</CNavbar>
			</>
		)
	}
}

export default FrameHeaderLeftMenu
