import React, { Component } from 'react'
import { Alert, Card, Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { registerUser } from '../../services/api/resgiter'
import isEmail from 'validator/lib/isEmail'
import HeaderTop from '../../lib/components/frame/header'

class AuthRegisterFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      password_re: '',
      email: '',
      firstname: '',
      lastname: '',
      job_title: '',
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeRegister = async () => {
    console.log(this.state)

    if (this.state.username.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'กรุณาระบุชื่อผู้ใช้งาน',
      })
      return
    }

    if (this.state.password.length < 6) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านต้องมีความยาว 6 ตัวอักษร',
      })
      return
    }

    if (this.state.password !== this.state.password_re) {
      Swal.fire({
        icon: 'error',
        text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
      })
      return
    }

    if (!isEmail(this.state.email)) {
      Swal.fire({
        icon: 'error',
        text: 'อีเมล์ไม่ถูกต้อง',
      })
      return
    }

    let user_info = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      email: this.state.email,
    }
    let user_data = {
      job_title: this.state.job_title,
    }
    let result = await registerUser(this.state.username, this.state.password, user_info, user_data)

    console.log(result)

    if (result.status >= 200 && result.status <= 250) {
      await Swal.fire({
        icon: 'success',
        text: 'สร้างผู้ใช้งานสำเร็จ',
      })
      window.location.replace('/login')
    } else {
      await Swal.fire({
        icon: 'error',
        text: result.data.message,
      })
    }
  }

  render() {
    return (
      <>
        <HeaderTop currentPage='onc'/>
        <Card className='shadow'>
          <Card.Body>
            <h4 className='text-center text-secondary'>สมัครสมาชิก</h4>
            <hr className='bg-secondary'></hr>
            <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
              <Form>
                <Form.Group className='mb-1' controlId='frmRegisterUsername'>
                  <Form.Label>ชื่อผู้ใช้งาน</Form.Label>
                  <Form.Control type='text' placeholder='' name='username' onChange={this.myChangeHandler} />
                </Form.Group>

                <Form.Group className='mb-1' controlId='frmRegisterPassword'>
                  <Form.Label>รหัสผ่าน</Form.Label>
                  <Form.Control type='password' placeholder='' name='password' onChange={this.myChangeHandler} />
                  <Form.Text className='text-muted'>รหัสผ่านต้องมีความยาว 6 ตัวอักษร</Form.Text>
                </Form.Group>
                <Form.Group className='mb-1' controlId='frmRegisterPasswordRe'>
                  <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                  <Form.Control type='password' placeholder='' name='password_re' onChange={this.myChangeHandler} />
                </Form.Group>
              </Form>
            </div>
            <h4 className='text-center text-secondary mt-4'>ข้อมูลส่วนตัว</h4>
            <hr className='bg-secondary'></hr>
            <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
              <Form>
                <Form.Group className='mb-1' controlId='frmRegisterFirstname'>
                  <Form.Label>ชื่อ</Form.Label>
                  <Form.Control type='text' placeholder='' name='firstname' onChange={this.myChangeHandler} />
                </Form.Group>

                <Form.Group className='mb-1' controlId='frmRegisterLastname'>
                  <Form.Label>นามสกุล</Form.Label>
                  <Form.Control type='text' placeholder='' name='lastname' onChange={this.myChangeHandler} />
                </Form.Group>
                <Form.Group className='mb-2' controlId='frmRegisterJobTitle'>
                  <Form.Label>ตำแหน่ง</Form.Label>
                  <Form.Control type='text' placeholder='' name='job_title' onChange={this.myChangeHandler} />
                </Form.Group>
                <Form.Group className='mb-2' controlId='frmRegisterEmail'>
                  <Form.Label>อีเมล์</Form.Label>
                  <Form.Control className='form-input' type='text' placeholder='' name='email' onChange={this.myChangeHandler} />
                </Form.Group>
                <Form.Group className='text-center'>
                  <Button variant='secondary' type='button' className='btn-submit' onClick={this.activeRegister}>
                    สมัครสมาชิก
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default AuthRegisterFrm
