import React, { useEffect, useState } from 'react'

import { apiLoginMoocAuthCode } from '../../lib/services/api/auth'
import { apiGetInfoMooc } from '../../lib/services/api/user'

function LoginWithAuthCode() {
	useEffect(() => {
		async function fetchData() {
			const urlParams = new URLSearchParams(window.location.search)
			const authorizeCode = urlParams.get('authorize_code')

			const jwtToken = urlParams.get('token')

			if (jwtToken) {
				localStorage.setItem('token', jwtToken)
			}

			if (authorizeCode) {
				console.log('Authorize code :', authorizeCode)
				try {
					const response = await apiLoginMoocAuthCode({
						authorize_code: authorizeCode,
					})

					const { token, expire, refresh_token, refresh_expire } = response.data
					if (response.status === 200) {
						console.log('Success', response)

						localStorage.setItem('mooc_token', token)
						localStorage.setItem('mooc_expired', expire)
						localStorage.setItem('mooc_refresh_token', refresh_token)
						localStorage.setItem('mooc_refresh_token_expired_at', refresh_expire)
					} else {
						console.error('Error', response)
					}
					let user_info = await apiGetInfoMooc()
					console.log(user_info)
					if (token) {
						window.location.replace('/dashboard')
					}
				} catch (error) {
					console.error('An error occurred', error)
				}
			} else {
				console.error('Authorization Code not found in the URL.')
			}
		}
		fetchData()
	}, [])
}
export default LoginWithAuthCode
