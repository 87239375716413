import React from 'react'
import logo from '../../assets/images/logo.png'
import {} from 'react-bootstrap'
import { CFooter, CImage } from '@coreui/react'
import officeRight from '../../assets/images/officeRight.png'
import '../../components/auth/style/login.css'
import '../../index.css'

function Footer() {
  return (
    <CFooter className='bg-transparent mt-5 border-footer on-bottom w-100' style={{ marginBottom: '-8px' }}>
      <div className='d-flex align-items-end'>
        <CImage src={logo} alt='logo' width={150} className='d-none d-md-block' style={{ marginBottom :'-10px'}} fluid />
        <div className='text-pri font-h3' style={{ whiteSpace: 'pre-line'}}>
          ระบบสื่อการเรียนการสอนออนไลน์
          <div className='text-secondary'>Bangkok Learning Hub - MOOC</div>
        </div>
      </div>
      <div>
        <CImage src={officeRight} align='end' className='d-none d-xl-block' width={250} fluid />
      </div>
    </CFooter>
  )
}

export default Footer
