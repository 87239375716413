import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash'
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Container } from 'react-bootstrap'
import { apiGetUserList, apiAdminResetPassword } from '../../../services/api/admin'
import { apiGetListCourseAssignmentFolder } from '../../../services/api/assignment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ModalCourse from './modal_course'

class AdminUserList extends Component {
	constructor(props) {
		super(props)

		this.state = {
			count: 0,
			page: 1,
			users: [],
			keyword: '',
			paginate_items: [],
		}
	}

	updatePaginateItem = (page, limit) => {
		let paginate_items = []

		for (let number = 1; number <= this.state.count / limit + 1; number++) {
			paginate_items.push(
				<Pagination.Item
					key={number}
					active={number === page}
					onClick={() => {
						this.loadUser(number)
					}}>
					{number}
				</Pagination.Item>
			)
		}

		this.setState({
			page: page,
			paginate_items: paginate_items,
			show_modal_course: false,
			modal_course_user: null,
		})
	}

	loadUser = async (page = 1, limit = 40) => {
		let user_data = await apiGetUserList(page, limit, [['first_name', 'asc']], [{ key: 'keyword', value: this.state.keyword }])

		this.setState({
			page: page,
			count: user_data.data.count,
			users: lodash.map(user_data.data.data, (user) => {
				let course_user = Array.isArray(user.user_course) ? user.user_course : []
				return {
					id: user.id,
					first_name: user.first_name,
					last_name: user.last_name,
					username: Array.isArray(user.user_method) && user.user_method[0] ? user.user_method[0].username : '---',
					job_title: user.user_data && user.user_data.job_title ? user.user_data.job_title : '---',
					roles: lodash.map(user.user_role, 'role_name').join(', '),
					course_count: course_user.length,
					complete_course_count: lodash.filter(course_user, { is_complete: true }).length,
					courses: lodash.map(course_user, 'course'),
					user_course: user.user_course,
				}
			}),
		})

		this.updatePaginateItem(page, limit)
	}

	componentDidMount() {
		this.loadUser()
	}

	loadCourseModal = (user) => {
		console.log('loadCourseModal', user)
		this.setState({
			modal_course_user: user,
			show_modal_course: true,
		})
	}

	updateUserPassword = async (user) => {
		if (window.confirm('ยืนยันการเปลี่ยนรหัสผ่านของ ' + user.first_name + ' ' + user.last_name + '?')) {
			try {
				let result = await apiAdminResetPassword({ user_id: user.id })
				alert('รหัสผ่านใหม่คือ ' + result.data.new_password)
			} catch (e) {
				alert('เกิดข้อผิดพลาด')
			}
		}
	}

	render() {
		return (
			<>
				<FrameHeaderLeftMenu active_menu='admin_user'></FrameHeaderLeftMenu>
				<Container fluid='md'>
					<h3>ผู้ใช้งานในระบบ</h3>
					<Card className='mt-3'>
						<Card.Body>
							<div className='d-flex justify-content-between '>
								<div>
									<Form
										className='d-flex'
										onChange={(evt) => {
											this.setState({ keyword: evt.target.value })
										}}
										onSubmit={(evt) => {
											evt.preventDefault()
											this.loadUser()
										}}>
										<FormGroup>
											<Form.Control style={{ width: '300px' }} className='form-input' type='text' size='sm' />
										</FormGroup>
										<Button className='btn-submit' type='submit' size='sm' variant='success'>
											ค้นหา
										</Button>
									</Form>
								</div>
								<div>
									<Pagination size='sm'>{this.state.paginate_items}</Pagination>
								</div>
							</div>
							<Table striped bordered hover size='sm' className='mt-3'>
								<thead>
									<tr>
										<th className='text-center'>#</th>
										<th className='text-center'>ชื่อ</th>
										<th className='text-center'>นามสกุล</th>
										<th className='text-center'>ตำแหน่ง</th>
										<th className='text-center'>Username</th>
										<th className='text-center'>Role</th>
										<th className='text-center'>วิชาที่เรียน / สำเร็จ</th>
										<th className='text-center'>เปลี่ยนรหัสผ่าน</th>
									</tr>
								</thead>
								<tbody>
									{this.state.users.map((user) => (
										<tr>
											<td className='text-center'>{user.id}</td>
											<td className='text-center'>{user.first_name}</td>
											<td className='text-center'>{user.last_name}</td>
											<td className='text-center'>{user.job_title}</td>
											<td className='text-center'>{user.username}</td>
											<td className='text-center'>{user.roles}</td>
											<td className='text-center'>
												{user.course_count} / {user.complete_course_count}
												<Button
													type='button'
													size='sm'
													className='ms-2'
													variant='success'
													onClick={() => {
														this.loadCourseModal(user)
													}}>
													<FontAwesomeIcon icon={faSearch} />
												</Button>
											</td>
											<td className='text-center'>
												<Button
													className='btn-submit'
													type='button'
													size='sm'
													variant='success'
													onClick={() => {
														this.updateUserPassword(user)
													}}>
													เปลี่ยน
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					<ModalCourse
						show={this.state.show_modal_course}
						user={this.state.modal_course_user}
						handleClose={() => {
							this.setState({ show_modal_course: false })
						}}
					/>
				</Container>
			</>
		)
	}
}

export default AdminUserList
