import React, { Component } from 'react'
import lodash from 'lodash'
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Modal } from 'react-bootstrap'
import { apiGetCourseAssignmentStudent, apiPutCourseAssignmentMy } from '../../services/api/assignment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

class ModalAssignmentPDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
      assignment: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.assignment && (!prevProps.assignment || prevProps.assignment.id !== this.props.assignment.id)) {
      apiGetCourseAssignmentStudent(parseInt(this.props.assignment.id)).then((assignment_res) => {
        if (assignment_res.data.assignment_type === 'pdf') {
          this.setState({
            assignment: assignment_res.data,
          })
        }
      })
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    return (
      this.state.assignment && (
        <Modal fullscreen={true} show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.assignment.assignment_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe style={{ border: '1px solid #666CCC' }} src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + this.state.assignment.assignment_data.url} frameBorder='1' scrolling='auto' height='99%' width='100%'></iframe>
          </Modal.Body>
        </Modal>
      )
    )
  }
}

export default ModalAssignmentPDF
