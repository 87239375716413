import React, { Component } from 'react'
import { Alert, Card, Form, Button } from 'react-bootstrap'
import FrameHeader from '../frame/header'
import Swal from 'sweetalert2'
import { apiSendForgotPassword } from '../../lib/services/api/auth'
import isEmail from 'validator/lib/isEmail'
import HeaderTop from '../../lib/components/frame/header'

class AuthForgorPasswordFrm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      is_complete: false,
    }
  }

  myChangeHandler = (event) => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  activeForgotPassword = async () => {
    console.log(this.state)

    if (this.state.email.length === 0) {
      Swal.fire({
        icon: 'error',
        text: 'กรุณาระบุอีเมล์',
      })
      return
    }

    try {
      let result = await apiSendForgotPassword(this.state.email)
      console.log(result)
    } catch (e) {
      console.error('e', e)
    }

    this.setState({ is_complete: true })
  }

  render() {
    return (
      <>
      <HeaderTop  currentPage='onc'/>
        <Card className='shadow'>
          <Card.Body>
            <h4 className='text-center text-secondary'>ลืมรหัสผ่าน</h4>
            <hr className='bg-secondary'></hr>
            <div className='offset-md-2 col-md-8 offset-lg-3 col-lg-6'>
              {this.state.is_complete ? (
                <Alert variant='secondary text-center'>เราได้ส่งอีเมล์ลิงก์สำหรับรีเซ็ตรหัสผ่านถึงคุณแล้ว ควรปรากฏในกล่องจดหมายของคุณภายในห้านาทีถัดไป หากคุณยังไม่เห็น โปรดตรวจสอบที่ไฟลเดอร์สแปมของคุณ !!</Alert>
              ) : (
                <Form>
                  <Alert variant='secondary text-center'>ระบุที่อยู่อีเมลที่คุณใช้ และเราจะส่งคำแนะนำในการรีเซ็ตรหัสผ่านของคุณ</Alert>
                  <Form.Group className='mb-3' controlId='frmRegisterUsername'>
                    <Form.Label>อีเมล</Form.Label>
                    <Form.Control className='form-input' type='text' placeholder='' name='email' onChange={this.myChangeHandler} />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Button variant='secondary' type='button' className='btn-submit' onClick={this.activeForgotPassword}>
                      ส่งอีเมลคำแนะนำ
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </div>
          </Card.Body>
        </Card>
     </>
    )
  }
}

export default AuthForgorPasswordFrm
