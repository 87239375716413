import axios from '../../config/axios'
import axiosskillmeo from '../../config/skillmeoaxios'
import axiosmooc from '../../config/moocaxios'

const apiGetInfo = async () => {
	try {
		const result = await axios.get(`/user/me`, {})
		localStorage.setItem('user_info', JSON.stringify(result.data))
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetInfoMooc = async () => {
	try {
		const result = await axiosmooc.get(`/v1/user/info`, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("mooc_token"),
			}
		})
		localStorage.setItem('mooc_user_info', JSON.stringify(result.data))
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiGetInfoSkillmeo = async () => {
	try {
		const result = await axiosskillmeo.get(`/user/my`, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("skillmeo_token"),
			}
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiResetPassword = async (Values) => {
	try {
		const result = await axios.put(`/v1/user/auth/me`, Values, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiEditProfile = async (Values) => {
	try {
		const result = await axios.put(`/user/update`, Values, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}
const apiEditProfileMooc = async (Values) => {
	try {
		const result = await axiosmooc.put(`/v1/user/account/my`, Values, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiUploadFileMooc = async (file) => {

	try {
  
	  let formData = new FormData()
	  formData.append('filelist', '["tmp_file"]')
	  formData.append('tmp_file', file);
  
	  const result = await axiosmooc.post(`/v1/upload/public`, formData, {
	  })
	  return result
	} catch (error) {
	  return error && error.response && error.response.data
	}
  }
  

const apiUploadProfilePicture = async (Values) => {
	try {
		const result = await axios.post(`/user/update/profile_picture`, Values, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiAddUser = async (Values) => {
	try {
		const result = await axios.post(`/v1/user`, Values, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiEditUserPasswordMooc = async (Values) => {
	const result = await axiosmooc.put(`/v1/user/auth/my/password`, Values, {})
	return result
}

const apiGetProfileTemplatePrivacy = async () => {
	try {
		const result = await axiosskillmeo.get('/user/profile/template_privacy', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('skillmeo_token'),
			},
		})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

const apiUpdateProfileTemplatePrivacy = async (payload) => {
	try {
		const result = await axiosskillmeo.patch('/user/profile/privacy', payload, {})
		return result
	} catch (error) {
		return error && error.response && error.response.data
	}
}

export {
	apiGetInfo,
	apiResetPassword,
	apiEditProfile,
	apiAddUser,
	apiEditUserPasswordMooc,
	apiGetInfoSkillmeo,
	apiGetInfoMooc,
	apiUploadProfilePicture,
	apiEditProfileMooc,
	apiGetProfileTemplatePrivacy,
	apiUpdateProfileTemplatePrivacy,
	apiUploadFileMooc,
}
