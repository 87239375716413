import axios from '../../config/axios';
import axiosskillmeo from '../../config/skillmeoaxios';
import axiosmooc from '../../config/moocaxios';
const apiGetOptionstInfo = async () => {
    try {
        const result = await axios.get(`/option/register`)
        return result
      } catch (error) {
        return error && error.response && error.response.data
      }
}
const apiGetOptionstSkillmeo = async () => {
  try {
      const result = await axiosskillmeo.get(`/options/register`)
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
}
const apiGetOptionstMooc = async () => {
  try {
      const result = await axiosmooc.get(`/v1/options/register`)
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
}

const apiGetOptionstMoocRole = async () => {
  try {
      const result = await axiosmooc.get(`/v1/user/role`)
      return result
    } catch (error) {
      return error && error.response && error.response.data
    }
}

export {
    apiGetOptionstInfo,
    apiGetOptionstSkillmeo,
    apiGetOptionstMooc,
    apiGetOptionstMoocRole
}