import React, { useState, useEffect } from 'react'
import { apiGetCourseAssignmentStudent, apiPutCourseAssignmentMy } from '../../services/api/assignment'
import Vimeo from '@u-wave/react-vimeo'

const CourseAssignmentVimeo = ({ assignment_id }) => {
	const [assignment, setAssignment] = useState(null)
	const [lastTime, setLastTime] = useState(0)

	const loadCourseAssignment = async () => {
		
		console.log('assignment_id', assignment_id)
		if (assignment_id === 0) {
			setAssignment(null)
			return
		}

		const assignmentRes = await apiGetCourseAssignmentStudent(parseInt(assignment_id))
		if (assignmentRes.data.assignment_type === 'video') {
			setAssignment(assignmentRes.data)
		}
	}

	const onPlay = (evt) => {
		console.log('On Play', evt)
		if (evt) {
			setLastTime(evt.seconds)
		}
	}

	const onPause = (evt) => {
		console.log('On Pause', evt)
	}

	const onSeeked = (evt) => {
		console.log('On Seeked', evt)
		if (evt) {
			setLastTime(evt.seconds)
		}
	}

	const onUpdate = (evt) => {
		console.log('On onUpdate', evt)
		if (evt.seconds - lastTime >= 5 && evt.seconds - lastTime < 5.5) {
			sendToServer(lastTime, evt.seconds)
			setLastTime(evt.seconds)
		}
	}

	const sendToServer = (from, to) => {
		if (assignment.assignment_progress > 99) return
		console.log('sendToServer', from, to)
		apiPutCourseAssignmentMy(assignment_id, {
			from: from,
			to: to,
		})
	}

	useEffect(() => {
		loadCourseAssignment()
	}, [assignment_id])

	return <>{assignment && <Vimeo video={assignment.assignment_data.url} height='500' responsive onPlay={onPlay} onPause={onPause} onSeeked={onSeeked} onTimeUpdate={onUpdate} />}</>
}

export default CourseAssignmentVimeo
