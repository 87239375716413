import axios from 'axios'

console.log('Axios config called')

let url = process.env.REACT_APP_SKILLMEO_ENDPOINT
const skillmeo_token = localStorage.getItem('skillmeo_token')

const instance = axios.create({
	baseURL: url,
	headers: {
		Authorization: `Bearer ${skillmeo_token}`,
	},
})

export default instance
