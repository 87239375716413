import React, { useEffect, useState } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import lodash, { size } from 'lodash'
import {} from 'react-bootstrap'
import { CContainer, CImage, CRow, CCol, CButton, CListGroup, CListGroupItem } from '@coreui/react'
import { apiGetPublicCourseDetail, apiEnrollCourse } from '../../services/api/course'
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

import LockCourse from '../../assets/images/LockCourse.png'
import bgLeftRightGreyOffice from '../../assets/images/bgLeftRightGreyOffice.png'
import officeLeft from '../../assets/images/officeLeft.png'
import btnLearn from '../../assets/images/btnLearn.png'
import btnStartLearn from '../../assets/images/btnStartLearn.png'
import { useParams, useHistory } from 'react-router-dom'

const CourseDetail = (props) => {
	const { course_id } = useParams()
	const [course, setCourse] = useState(null)
	const [courseAssignmentFolders, setCourseAssignmentFolders] = useState([])
	const [courseStatus, setCourseStatus] = useState(0) // 0 = not registered, 1 = learning, 2 = complete
	const [courseName, setCourseName] = useState('')
	const [courseDetail, setCourseDetail] = useState('')

	useEffect(() => {
		loadCourse()
	}, [props])

	const loadCourse = async () => {
		try {
			const courseData = await apiGetPublicCourseDetail(parseInt(course_id))
			const courseAssignmentFolders = await apiGetListCourseAssignmentFolder(parseInt(course_id))

			const myInfo = JSON.parse(localStorage.getItem('mooc_user_info'))

			let status = 0
			const isAttend = lodash.filter(courseData.data.course_student, { user_id: myInfo.id })

			if (isAttend.length > 0) {
				status = 1
				if (isAttend[0].is_course_complete === true) {
					status = 2
				}
			}

			setCourse(courseData.data)
			setCourseStatus(status)
			setCourseAssignmentFolders(courseAssignmentFolders.data)
		} catch (error) {
			console.error('Error while loading course: ', error)
		}
	}

	const enterCourse = () => {
		window.location.replace(`/course/${course_id}/learn`)
	}

	const enrollCourse = async () => {
		try {
			await apiEnrollCourse(course_id)
			await Swal.fire({
				icon: 'success',
				text: 'Successfully enrolled in this course.',
			})

			enterCourse()
		} catch (error) {
			await Swal.fire({
				icon: 'error',
				text: 'An error occurred. Could not enroll in this course.',
			})
		}
	}

	const splitString = (text) => {
		const myArray = text.split(',')
		let result = ''
		// console.log('arR', myArray)
		if (myArray.length) {
			for (let index = 0; index < myArray.length; index++) {
				result += '<li>' + myArray[index] + '</li>'
			}
		}
		// document.getElementById('instructor-name').innerHTML = result
		document.getElementById('instructor-name-top').innerHTML = result
		document.getElementById('instructor-name-left').innerHTML = result
	}

	useEffect(() => {
		if (course) {
			setCourseName(course.course_name.replaceAll('"', '').replace(')', '').split('-'))
			setCourseDetail(course.course_data.course_detail_1)
		}
	}, [course])

	const bgStyle2 = {
		background: `url(${bgLeftRightGreyOffice}) no-repeat bottom center`,
		backgroundSize: `115%`,
		overflow: 'hidden',
	}
	const bgStyle3 = {
		background: `url(${officeLeft}) no-repeat bottom center, #e0e1e1`,
		backgroundSize: `20%`,
		backgroundPosition: 'bottom left',
		overflow: 'hidden',
	}

	return (
		<>
			<FrameHeaderLeftMenu active_menu='course_list'></FrameHeaderLeftMenu>
			<CContainer className='bg-four' fluid>
				<CRow xs={{ cols: 1 }} md={{ cols: 2 }}>
					<CCol>
						<CImage src={LockCourse} fluid />
					</CCol>
					<CCol style={{ marginBottom: '80px' }} className='align-self-center text-white '>
						<h4 className='text-uppercase mt-5'>{courseName[0]}</h4>
						<h2 className='mb-4 text-pri'>{courseName[1]}</h2>
						<hr style={{ color: 'white', marginLeft: '0', borderWidth: '1px', width: '100%' }} />
						<h4 className='fw-normal text-sec' style={{ marginTop: '1em', marginBottom: '1em' }}>
							{courseDetail.split(' ').reverse().slice(1).reverse().join(' ')}
						</h4>
						<hr style={{ color: 'white', marginLeft: '0', borderWidth: '1px', width: '100%' }} />
						<h4 className='mt-4 fw-normal text-pri '>
							<i>{courseDetail.split(' ').pop()}</i>
						</h4>
					</CCol>
				</CRow>
			</CContainer>
			<div style={bgStyle2}>
				<CContainer>
					<CRow>
						<CCol xs={12} className='mt-5 mb-5 m-auto w-my'>
							<h3 className='text-pri font-h3' style={{ lineHeight: '1.5' }}>
								รายละเอียด
							</h3>
							<p className='font-p'>{course && course.course_data.description}</p>
						</CCol>
					</CRow>
				</CContainer>
			</div>
			<div style={bgStyle3}>
				<CContainer fluid>
					<CRow className='d-md-none'>
						<CCol xs={12} className='mt-5 mb-5'>
							<h3 className=' mb-3 text-b text-pri'>เนื้อหาของคอร์สนี้</h3>
							<CListGroup>
								{courseAssignmentFolders.map((folder, i) => (
									<CListGroupItem className='p-3 bg-transparent ' style={{ borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }} key={i}>
										<span className='text-sec'>{folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;</span>
										{folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
									</CListGroupItem>
								))}
							</CListGroup>
						</CCol>
					</CRow>
					<CRow>
						<CCol xs={6} className='mt-md-5 mb-5 text-center'>
							{course && (
								<CImage align='center' width={428} className='border-image bg-white' src={process.env.REACT_APP_STORAGE_ENDPOINT + '/' + course.course_data.cover_photo} fluid />
							)}
							<div id='monitorLeg' className='mt-2 mb-3 bg-white' style={{ maxWidth: '428px' }}></div>
							<ul id='instructor-name-top' className='d-none d-md-inline-block font-h5'>
								{course ? splitString(course.course_data.course_instructor) : null}
							</ul>
							{courseStatus === 0 && (
								<div class='mt-4'>
									<CButton className='bg-transparent border-0' onClick={enrollCourse}>
										<CImage width={200} src={btnStartLearn} fluid />
									</CButton>
								</div>
							)}
							{courseStatus === 1 && (
								<div className='mt-4'>
									<CButton className='bg-transparent border-0' onClick={enterCourse}>
										<CImage width={200} src={btnLearn} fluid />
									</CButton>
								</div>
							)}
							{courseStatus === 2 && (
								<div className='mt-4'>
									<CButton className='bg-transparent border-0' onClick={enterCourse}>
										<CImage width={200} src={btnLearn} fluid />
									</CButton>
									<div className='fs-xs mt-1 text-muted'>*คุณเคยศึกษาบทเรียนนี้จนจบแล้ว</div>
								</div>
							)}
						</CCol>
						<CCol xs={6} className='d-md-none'>
							<ul id='instructor-name-left' className='d-inline-block font-h5'>
								{course ? splitString(course.course_data.course_instructor) : null}
							</ul>
						</CCol>
						<CCol xs={6} className='mt-5 mb-5 d-none d-md-block'>
							<h5 className=' mb-3 text-b text-pri'>เนื้อหาของคอร์สนี้</h5>
							<CListGroup>
								{courseAssignmentFolders.map((folder, i) => (
									<CListGroupItem className='p-3 bg-transparent' style={{ borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }} key={i}>
										<span className='text-sec'>{folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;</span>
										{folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
									</CListGroupItem>
								))}
							</CListGroup>
						</CCol>
					</CRow>
				</CContainer>
			</div>
		</>
	)
}

export default CourseDetail
