import React, { Component }  from 'react'

class UserManual extends Component {
    render() {
        return (
            <>
                <embed src="/shared/test.pdf" type="application/pdf" width="100%" height="800px" />
            </>
        )
    }
}

export default UserManual;