import axios from 'axios'

console.log('Axios lib config called')

let url = process.env.REACT_APP_MOOC_ENDPOINT
const mooc_token = localStorage.getItem('mooc_token')

const instance = axios.create({
	baseURL: url,
	headers: {
		Authorization: `Bearer ${mooc_token}`,
	},
})

export default instance