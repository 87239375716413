import React, { useState, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask'
import { FileUpload } from 'primereact/fileupload'
import { Toast } from 'primereact/toast'
import { Container, Form, Col, Row, Card, Image } from 'react-bootstrap'
import { apiGetOptionstInfo } from '../../../services/api/options'
import { apiGetInfoMooc, apiEditProfile, apiUploadProfilePicture } from '../../../services/api/user'
import FileResizer from 'react-image-file-resizer'
import '../style/header.css'

function UserInfoModal() {
	const [visible, setVisible] = useState(false)
	const [schoolOptions, setSchoolOptions] = useState([])
	const [provinceOptions, setProvinceOptions] = useState([])
	const [districtOptions, setDistrictOptions] = useState([])
	const [areaOption, setAreaOption] = useState([])
	const [districtAllOptions, setDistrictAllOptions] = useState([])
	const [formData, setFormData] = useState({})
	const [jsonContent, setJsonContent] = useState('')
	const fileUploadRef = useRef(null)
	const [selectedFile, setSelectedFile] = useState(null)

	const toast = useRef(null)
	// let search = data.profileTemplate.find((item) => item.template_name === 'profile')
	useEffect(() => {
		if (!localStorage.getItem('token')) {
			return
		}
		fetchData()
	}, [])

	const parseOptionsID = (options, id) => {
		return options.find((f) => {
			return f.code === id
		})
	}

	const parseDistrictOptionsID = (options, id) => {
		return options.find((f) => {
			return f.district_id === id
		})
	}

	// Add a function to update district options when a province is selected
	const updateDistrictOptions = (allOptions, provinceCode) => {
		if (allOptions[provinceCode]) {
			setDistrictOptions(allOptions[provinceCode])
		} else {
			setDistrictOptions([]) // Clear district options if no data is available for the selected province
		}
	}

	const fetchData = async () => {
		try {
			const options = await apiGetOptionstInfo()
			if (options && options.status === 200) {
				const schools = options.data.schools.map(({ school_code, school_name }) => ({ name: `${school_code} - ${school_name}`, code: parseInt(school_code) }))
				setSchoolOptions(schools)

				const provinces = options.data.provinces.map(({ province_id, province_name }) => ({ name: province_name, code: parseInt(province_id) }))
				setProvinceOptions(provinces)

				const schoolareas = options.data.areas.map(({ id, school_area_name }) => ({ name: school_area_name, code: parseInt(id) }))
				setAreaOption(schoolareas)

				const districts = options.data.districts
				setDistrictAllOptions(districts)
				let user_info = null
				const res = await apiGetInfoMooc()
				if (res && res.status === 200) {
					user_info = res.data
				}

				if (user_info) {
					const { role_id, pic_profile, title, first_name, last_name, email, school_id, school_area_id, province_id, district_id, tel, position } = user_info
					updateDistrictOptions(districts, province_id)

					setFormData({
						role_id: 1,
						pic_profile: pic_profile ? `${process.env.REACT_APP_MAIN_STORAGE}/${pic_profile}` : '/shared/profile.png',
						title: title || '',
						first_name: first_name || '',
						last_name: last_name || '',
						email: email || '',
						school: parseOptionsID(schools, school_id),
						school_area: parseOptionsID(schoolareas, school_area_id),
						province: parseOptionsID(provinces, province_id),
						district: parseDistrictOptionsID(districts[province_id], district_id),
						position: position || '',
						tel: tel || '',
					})
					setJsonContent(JSON.stringify(formData, null, 2))
				}
			}
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}

	const handleFileChange = async (e) => {
		let file = e.files[0]

		try {
			// when image is bigger than 300 x 300 then resize
			FileResizer.imageFileResizer(
				file,
				300, // Width
				300, // Height
				'PNG', // Format (e.g., JPEG, PNG)
				100, // Quality (0-100)
				0, // Rotation (degrees)
				async (resizedUri) => {
					setSelectedFile(resizedUri)
				},
				'blob' // Output type ('blob' or 'base64')
			)
		} catch (error) {
			console.error('Error resizing image:', error)
		}
	}

	const openModal = async () => {
		setVisible(true)
	}

	const hideModal = () => {
		setVisible(false)
	}

	const handleUpdateProfile = async (e) => {
		e.preventDefault()
		let payload = {
			first_name: formData?.first_name,
			last_name: formData?.last_name,
			email: formData?.email,
			school_id: formData?.school?.code,
			school_area_id: formData?.school_area?.code,
			province_id: formData?.province?.code,
			district_id: formData?.district?.district_id,
			position: formData?.position,
			tel: formData?.tel,
		}
		console.log(formData, '<<<<< formData')
		try {
			const response = await apiEditProfile(payload)
			if (response.status === 200) {
				console.log(response)
				toast.current.show({ severity: 'success', summary: 'Success', detail: 'Form submitted!' })
			}

			if (selectedFile) {
				var formdata = new FormData()
				formdata.append('file', selectedFile)
				let responseUploader = await apiUploadProfilePicture(formdata)
				if (responseUploader.status === 200) {
					toast.current.show({ severity: 'success', summary: 'Success', detail: 'Upload Success' })
				}
			}
			await fetchData()
		} catch (error) {
			console.error(error)
			toast.current.show({ severity: 'error', summary: 'Failure', detail: 'Form update failed' })
		}
		hideModal()
	}

	return (
		<>
			<div className='header-profile-group d-flex' onClick={openModal}>
				{formData.pic_profile && (
					<Image src={`${formData.pic_profile}`} style={{ height: '70px', width: '70px' }} className='object-fit-cover border-2 border-success' alt='' roundedCircle fluid />
				)}
				<div className='header-profile-text-group'>
					<h6>{formData.email}</h6>
					<span style={{ fontSize: '1.0em' }}>{formData.position}</span>
				</div>
			</div>
			<Dialog
				header='โปรไฟล์'
				visible={visible}
				style={{ width: '500px', background: 'bg-image' }}
				modal
				onHide={hideModal}
				headerClassName='border-bottom black bg-image'
				footer={
					<div className='button-group add-edu ml-2 mb-3 mt-3 '>
						<Button
							label='บันทึก'
							type='submit'
							className='p-button-upload mr-2'
							onClick={(e) => {
								handleUpdateProfile(e)
							}}
						/>
						<Button label='ยกเลิก' onClick={hideModal} className='p-button-secondary' />
					</div>
				}>
				<div className='profile-image-select pl-5 pr-5 '>
					<Image src={formData.pic_profile} className='object-fit-cover' style={{ height: '300px', width: '300px' }} rounded fluid />
					<FileUpload
						name='fileUpload'
						accept='.jpg,.svg,.png'
						chooseLabel='เลือกรูป'
						uploadLabel='Upload'
						customUpload
						auto
						onSelect={handleFileChange}
						ref={fileUploadRef}
						onRemove={() => {
							setSelectedFile(null)
						}}
					/>
					{/* <FileUpload
						mode='basic'
						accept='.jpg,.svg,.png'
						chooseLabel='เลือกรูป'
						uploadLabel='Upload'
						ref={fileUploadRef}
						onRemove={() => {
							setSelectedFile(null)
						}}
						onSelect={handleFileChange}
						maxFileSize={1000000}
					/> */}
				</div>
				<div className='p-5 flex flex-wrap gap-3 mb-4'>
					<div className='flex-auto font-bold block'>
						<label htmlFor='name'>ชื่อ :</label>
						<InputText
							id='sidebar-profile-name'
							className='w-full'
							name='name'
							value={formData.first_name}
							onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
							required
						/>
					</div>
					<div className='flex-auto font-bold block'>
						<label htmlFor='surname'>นามสกุล :</label>
						<InputText
							id='sidebar-profile-surname'
							className='w-full'
							name='surname'
							value={formData.last_name}
							onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
							required
						/>
					</div>
					<div className='flex-auto font-bold block'>
						<label htmlFor='email'>อีเมล :</label>
						<InputText
							id='sidebar-profile-email'
							className='w-full'
							name='email'
							value={formData.email}
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
							required
						/>
					</div>
					{formData.role_id !== 1 && (
						<>
							<div className='flex-auto font-bold block'>
								<label htmlFor='school'>โรงเรียน :</label>
								<Dropdown
									value={formData.school}
									onChange={(e) => {
										setFormData({ ...formData, school: e.value })
									}}
									options={schoolOptions}
									optionLabel='name'
									placeholder='รหัส-ชื่อโรงเรียน'
									virtualScrollerOptions={{ itemSize: 25 }}
									filter
									className='w-full'
									required
								/>
							</div>
							<div className='flex-auto font-bold block'>
								<label htmlFor='school_area'>สำนักงานเขต :</label>
								<Dropdown
									value={formData.school_area}
									onChange={(e) => {
										setFormData({ ...formData, school_area: e.value })
									}}
									options={areaOption}
									optionLabel='name'
									placeholder='สำนักงานเขต'
									virtualScrollerOptions={{ itemSize: 25 }}
									filter
									className='w-full'
									required
								/>
							</div>
						</>
					)}

					<div className='flex-auto font-bold block'>
						<label htmlFor='province'>จังหวัด</label>
						<Dropdown
							value={formData.province}
							onChange={(e) => {
								updateDistrictOptions(districtAllOptions, e.value.code) // Update district options based on the selected province
								setFormData({ ...formData, province: e.value })
							}}
							options={provinceOptions}
							optionLabel='name'
							placeholder='จังหวัด'
							className='w-full'
							filter
							required
						/>
					</div>

					{formData.role_id === 1 && (
						<>
							<div className='flex-auto font-bold block'>
								<label htmlFor='district'>เขต/อำเภอ</label>
								<Dropdown
									value={formData.district}
									onChange={(e) => {
										setFormData({ ...formData, district: e.value })
										console.log(e.value)
									}}
									options={districtOptions}
									optionLabel='district_name'
									placeholder='เขต/อำเภอ'
									virtualScrollerOptions={{ itemSize: 25 }}
									className='w-full'
									filter
									required
								/>
							</div>
						</>
					)}

					{formData.role_id === 3 && (
						<div className='flex-auto font-bold block'>
							<label htmlFor='position'>ตำแหน่ง :</label>
							<InputText
								id='sidebar-profile-position'
								className='w-full'
								name='position'
								value={formData.position}
								onChange={(e) => setFormData({ ...formData, position: e.target.value })}
								required
							/>
						</div>
					)}
					<div className='flex-auto font-bold block'>
						<label htmlFor='tel'>เบอร์โทรศัพท์ :</label>
						<InputMask
							id='sidebar-profile-tel'
							className='w-full'
							name='tel'
							value={formData.tel}
							onChange={(e) => setFormData({ ...formData, tel: e.target.value })}
							mask='999-999-9999'
							placeholder='999-999-9999'
							required
						/>
					</div>
					{/* For test json data */}
					{/* <div className='flex-auto font-bold block' onClick={() => setJsonContent(JSON.stringify(formData, null, 2))}>
						<pre>{jsonContent}</pre>
					</div> */}
				</div>
			</Dialog>
			<Toast ref={toast} position='top-center' />
		</>
	)
}

export default UserInfoModal
