import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash'
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Modal } from 'react-bootstrap'
import { apiGetUserList } from '../../../services/api/admin'
import { apiGetListCourseAssignmentFolder } from '../../../services/api/assignment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

class ModalCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_modal: this.props.show,
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    return this.props.user ? (
      <Modal show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            วิชาของผู้เรียน {this.props.user.first_name} {this.props.user.first_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size='sm' className='mt-3'>
            <thead>
              <tr>
                <th>ชื่อวิชา</th>
                <th>% ของการเรียน</th>
              </tr>
            </thead>
            <tbody>
              {this.props.user.user_course.map((user_course) => (
                <tr>
                  <th>{user_course.course?.course_name} </th>
                  <td>{user_course.is_course_complete ? '100%' : user_course.learning_progress.toFixed(2) + '%'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    ) : (
      <></>
    )
  }
}

export default ModalCourse
