import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { apiLogout } from '../../lib/services/api/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt, faLock } from '@fortawesome/free-solid-svg-icons'
import { CImage, CNavbar, CContainer, CNavbarBrand, CNavLink } from '@coreui/react'
import logo from '../../assets/images/logo.png'
import user from '../../assets/images/user.png'
import logout from '../../assets/images/logout.png'
import '../../index.css'
class HeaderTop extends Component {
  constructor(props) {
    super(props)
    let user_info = null
    if (localStorage.getItem('mooc_user_info')) {
      try {
        user_info = JSON.parse(localStorage.getItem('mooc_user_info'))
        // console.log('user_info', user_info)
      } catch (e) {}
    }
    this.state = {
      user_info: user_info,
      bg: '',
    }
  }
  listenScrollEvent = (e) => {
    if (window.scrollY > 50) {
      this.setState({ bg: 'white' })
    } else {
      this.setState({ bg: '' })
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  logout = async () => {
    console.log('logout called')

    await apiLogout()
    localStorage.removeItem('mooc_token')
    localStorage.removeItem('mooc_expire')
    localStorage.removeItem('mooc_user_info')
    this.props.history.push('/')
  }

  render() {
    return (
      <>
        <CNavbar style={{ borderBottom: '2px solid rgb(40, 130, 90)' }} colorScheme='light'>
          <CContainer className='w-my' fluid>
            <CNavbarBrand component={Link} to={'/'}>
              <CImage src={logo} className='d-inline-block align-top ' width={120}  fluid />
            </CNavbarBrand>
            <div className='d-inline-flex'>
              {!localStorage.getItem('mooc_token') ? (
                <CNavLink component={Link} to='/login' className='text-pri'>
                  เข้าสู่ระบบ <FontAwesomeIcon className='text-tri' icon={faUnlockAlt} />
                </CNavLink>
              ) : (
                <>
                  <CNavLink component={Link} to='/setting'>
                    <div className='tree-nav d-none d-xl-block mt-md-1 '>
                      <div class='frame-nav'>
                        <CImage style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={this.state.user_info.pic_profile ? `${process.env.REACT_APP_STORAGE_ENDPOINT}/${this.state.user_info.pic_profile}` : '/default_user.png'} fluid />
                      </div>
                    </div>
                    <CImage src={user} className='d-block d-xl-none mx-1' style={{ maxHeight: '30px' }} fluid />
                  </CNavLink>
                  <CNavLink className='text-capitalize d-none d-xl-block mt-xl-4 text-sec  mx-md-1'>
                    <h5>
                      {this.state.user_info.first_name} {this.state.user_info.last_name}
                    </h5>
                    <p>
                      <span className='text-pri fw-bold text-capitalize'>Position</span> : {this.state.user_info.data?.job_title}
                    </p>
                  </CNavLink>
                  <CNavLink href='#' onClick={this.logout} className='text-pri mt-xl-5  mx-md-1'>
                    <div className='fs-4 d-none d-xl-inline-block'>
                      ออกจากระบบ <FontAwesomeIcon className='text-tri' icon={faLock} />
                    </div>
                    <CImage src={logout} className='d-block d-xl-none mx-1' style={{ maxHeight: '30px' }} fluid />
                  </CNavLink>
                </>
              )}
            </div>
          </CContainer>
        </CNavbar>
      </>
    )
  }
}

export default HeaderTop
