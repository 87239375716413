import axios from 'axios'
//require('dotenv').config()

console.log('Axios config called')

let url = process.env.REACT_APP_MAIN_ENDPOINT

const token = localStorage.getItem('token')

const instance = axios.create({
	baseURL: url,
	headers: {
		Authorization: `Bearer ${token}`,
	},
})

instance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response.status === 401) {
			//clear all store data
			// localStorage.clear()
			// window.location.replace('/')
		}
		return Promise.reject(error)
	}
)

export default instance
