import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import lodash from 'lodash'
import { CContainer, CImage, CRow, CCol, CButton } from '@coreui/react'
import { apiGetMyCourseDetail, apiEnrollCourse } from '../../services/api/course'
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faVideo,
	faPlay,
	faQuestion,
	faStopCircle,
	faPauseCircle,
	faPlayCircle,
	faCheckCircle,
	faFileSignature,
	faArrowCircleDown,
	faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import CourseAssignmentVimeo from './assignment_vimeo'
import ModalAssignmentPDF from './assignment_modal_pdf'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import play from '../../assets/images/play.png'
import bgLearn from '../../assets/images/bgLearn.png'
import bgLeftRightGreyOffice from '../../assets/images/bgLeftRightGreyOffice.png'
import { BtnFooter } from '../btnFooter'

const CourseAssignment = (props) => {
	const { course_id } = useParams()

	const [showModalAssignmentPdf, setShowModalAssignmentPdf] = useState(false)
	const [activeAssignmentPdf, setActiveAssignmentPdf] = useState(null)
	const [course, setCourse] = useState(null)
	const [courseAssignmentFolders, setCourseAssignmentFolders] = useState([])
	const [activeAssignmentId, setActiveAssignmentId] = useState(0)
	const [activeVideoAssignmentId, setActiveVideoAssignmentId] = useState(0)
	const [activeFolderId, setActiveFolderId] = useState(-1)
	const [showFolderId, setShowFolderId] = useState({})
	const [courseAssignmentStructs, setCourseAssignmentStructs] = useState({})
	const [courseAssignmentFolderSummaries, setCourseAssignmentFolderSummaries] = useState({})

	useEffect(() => {
		loadCourseAssignment(true)
	}, [])

	const loadCourseAssignment = async (initShowFolder = false) => {
		let course_data = await apiGetMyCourseDetail(parseInt(course_id))
		let course_assignment_folders = await apiGetListCourseAssignmentFolder(parseInt(course_id))

		let course_assignment_structs = {}
		let show_folder_id = {}
		let course_assignment_folder_summaries = {}

		course_assignment_structs[0] = lodash.sortBy(lodash.filter(course_data.data.course_assignment, { course_assignment_folder_id: null }), ['assignment_order'])
		for (let i = 0; i < course_assignment_folders.data.length; i++) {
			show_folder_id[course_assignment_folders.data[i].id.toString()] = false
			course_assignment_structs[course_assignment_folders.data[i].id] = lodash.sortBy(
				lodash.filter(course_data.data.course_assignment, { course_assignment_folder_id: course_assignment_folders.data[i].id }),
				['assignment_order']
			)
			course_assignment_folder_summaries[course_assignment_folders.data[i].id] = {
				video: lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], { assignment_type: 'video' }).length,
				pdf: lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], { assignment_type: 'pdf' }).length,
			}
		}

		setCourseAssignmentStructs(course_assignment_structs)
		setCourseAssignmentFolderSummaries(course_assignment_folder_summaries)
		if (initShowFolder) {
			setShowFolderId(show_folder_id)
		}

		setCourse(course_data.data)
		setCourseAssignmentFolders(course_assignment_folders.data)
	}

	const activeAssignment = async (assignment) => {
		setActiveVideoAssignmentId(assignment.id)
		if (assignment.can_access === false) return

		if (assignment.assignment_type === 'quiz') {
			if (assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90.0) {
				Swal.fire({
					icon: 'info',
					text: 'คุณทำแบบทดสอบนี้ผ่านแล้ว',
				})
				return
			} else {
				window.location.href = '/course/' + assignment.course_id + '/quiz/' + assignment.id
				return
			}
		}
	}
	const renderFolder = (folder, idx) => {
		return (
			<>
				<>
					<li key={idx} className='p-3 bg-transparent ' style={{ borderBottom: '1px solid white', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
						<span className='text-pri'>{folder.assignment_folder_name.replaceAll('"', '').split(' ')[0]}&nbsp;:&nbsp;</span>
						{folder.assignment_folder_name.replaceAll('"', '').split(' ').slice(1).join(' ')}
						{courseAssignmentStructs[folder.id].map((assignment) => (
							<>
								<span className='text-white'> | </span>
								<CButton
									className='div_assignment bg-transparent border-0'
									style={{ color: '#ef85b7' }}
									key={assignment.id}
									disabled={assignment.can_access === false ? 'disable' : activeAssignmentId === assignment.id ? 'active' : ''}
									onClick={() => activeAssignment(assignment)}>
									{/* {assignment.assignment_type === 'video' && <FontAwesomeIcon icon={faVideo} />} */}
									{assignment.assignment_type === 'video' && activeAssignmentId === assignment.id && <CImage src={play} width={25} style={{ marginTop: '-10px' }} fluid />}

									{assignment.assignment_type === 'video' && activeAssignmentId !== assignment.id && (
										<>
											{/* {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 0 && <FontAwesomeIcon icon={faPauseCircle} />}
	                    {(assignment.course_assignment_user_score.length === 0 || assignment.course_assignment_user_score[0].assignment_progr === 0) && <FontAwesomeIcon icon={faStopCircle} />}
	                    {assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90 && <FontAwesomeIcon icon={faCheckCircle} />} */}
											{assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 0 && (
												<CImage src={play} width={25} style={{ marginTop: '-10px' }} fluid />
											)}
											{(assignment.course_assignment_user_score.length === 0 || assignment.course_assignment_user_score[0].assignment_progr === 0) && (
												<CImage src={play} width={25} style={{ marginTop: '-10px' }} fluid />
											)}
											{assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90 && (
												<CImage src={play} width={25} style={{ marginTop: '-10px' }} fluid />
											)}
										</>
									)}

									{assignment.assignment_type === 'quiz' && <FontAwesomeIcon icon={faQuestion} />}
									{assignment.assignment_type === 'pdf' && <FontAwesomeIcon icon={faFileSignature} />}
								</CButton>
							</>
						))}
					</li>
				</>
			</>
		)
	}
	const bgStyle1 = {
		background: `url(${bgLeftRightGreyOffice}) no-repeat bottom center, linear-gradient(to bottom, #bdddcb 50%, #ffff 50%)`,
		backgroundSize: `100%`,
		overflow: 'hidden',
	}

	const bgStyle2 = {
		background: `url(${bgLearn}), #e0e1e1`,
		backgroundPosition: 'left bottom',
		backgroundSize: '40%',
		backgroundRepeat: 'no-repeat',
		overflow: 'hidden',
		height: '100%',
	}

	return (
		<>
			<FrameHeaderLeftMenu active_menu='course_list' />
			<div style={bgStyle1}>
				<CContainer className='w-my' fluid>
					<CRow>
						<CCol className='text-center mt-5 mb-5'>
							<h5 className='fw-bold text-pri'>{course && course.course_name.replaceAll('"', '').replace(')', '').split('(')[0]}</h5>
							<h5 className='fw-normal text-white mb-4'>{course && course.course_name.replaceAll('"', '').replace(')', '').split('(')[1]}</h5>
							<div className='border-image bg-sec'>
								<CourseAssignmentVimeo assignment_id={activeVideoAssignmentId} />
							</div>
							{course && (
								<>
									{course.course_student[0].is_course_complete === true && <span>ศึกษาวิชานี้สำเร็จแล้ว</span>}
									{!course.course_student[0].is_course_complete && (
										<>
											<p className='mt-3 text-end m-auto'>
												กำลังศึกษาอยู่&nbsp;&nbsp;
												<span className='fw-bold text-sec'>
													<CImage src={play} width={30} style={{ marginTop: '-10px' }} fluid />
													&nbsp;
													{course.course_student[0].learning_progress}%
												</span>
											</p>
											<p className='text-start mt-3 m-auto w-my'>
												<span className='fw-bold text-pri'>{course.course_data.description.split(' ')[0]}&nbsp;</span>
												{course.course_data.description.split(' ').slice(1).join(' ')}
											</p>
										</>
									)}
								</>
							)}
						</CCol>
					</CRow>
				</CContainer>
			</div>

			<div style={bgStyle2}>
				<CContainer style={{ marginBottom: '10%' }} fluid>
					<CRow className='mt-5 mb-5'>
						<CCol className='text-center' xs={12} md={6}>
							{course && (
								<div className='w-my m-auto'>
									<hr className='text-white' />
									<h5 className='fw-normal text-sec' style={{ lineHeight: '2' }}>
										{course.course_data.course_detail_1.split(' ').reverse().slice(1).reverse().join(' ')}
										<h2 className='fw-normal text-pri' style={{ lineHeight: '1.5' }}>
											<i>{course.course_data.course_detail_1.split(' ').pop()}</i>
										</h2>
									</h5>
									<hr className='text-white' />
								</div>
							)}
						</CCol>
						<CCol>
							<ul>
								{courseAssignmentFolders.map((folder, idx) => renderFolder(folder, idx + 1))}
								{/* Additional rendering logic */}
							</ul>
						</CCol>
						<div className='mt-5 d-block d-md-none'></div>
					</CRow>
				</CContainer>
			</div>

			<CContainer className='bg-skypink' style={{ height: '20vh' }} fluid>
				<BtnFooter />
			</CContainer>

			<ModalAssignmentPDF
				show={showModalAssignmentPdf}
				assignment={activeAssignmentPdf}
				handleClose={() => {
					setShowModalAssignmentPdf(false)
				}}
			/>
			<CButton
				className='div-back-btn border-0 fw-normal text-dark bg-sec'
				shape=''
				onClick={() => {
					window.location.replace('/course/' + (course ? course.id : '') + '/detail')
				}}>
				<b>BACK</b>
			</CButton>
		</>
	)
}
export default CourseAssignment
